















































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import {tourStore} from "@/store/modules/tour";

@Component({
  name: 'dialog-tour',
  components: {BaseDialog}
})
export default class DialogTour extends Vue {
  @Prop() value!: boolean

  get show() {
    return this.value
  }

  get isCompleted() {
    return tourStore.tourCompleted
  }

  get title() {
    if (this.isCompleted) {
      return 'You’ve done it!'
    }
    return 'First time using Pigeon?'
  }

  get text() {
    if (this.isCompleted) {
      return 'If you’d like a refresher, you can always take the tour again<br>' +
          'or visit our <a class="text-decoration-none" href="https://www.youtube.com/watch?v=2JtVgCcIwdI&ab_channel=Pigeon" target="_blank">help center</a> for additional videos and tutorials'
    }
    return 'Pigeon makes it easier for anyone in your organization to<br>' +
        ' text, talk or video with your customers in one secure place,<br>' +
        'all while maintaining your privacy, collaborating internally<br>' +
        'and externally, and monitoring real-time insights.'
  }

  set show(value: boolean) {
    this.$emit('input', value)
  }

  onClick() {
    this.show = false
    this.$emit('on-confirm', true)
  }

  onTourAgain() {
    tourStore.changeTour(1)
    this.show = false
  }
}
